@import-normalize;
@import './global.scss';
@import './colors.scss';


@font-face {
  font-family: "DancingScript";
  src: 
    url("./../fonts/DancingScript-Regular.ttf") format("ttf"), 
    url(https://fonts.gstatic.com/s/dancingscript/v24/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup8.woff2) format('woff2');;
}

@font-face {
  font-family: "Inter";
  src: 
    url("./../fonts/Inter-VariableFont.ttf") format("ttf"), 
    url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZ9hiA.woff2) format('woff2');;
}
