$bp-sm-mobile: 405px;
$bp-mobile: 567px;
$bp-tablet: 768px;
$bp-lg: 992px;
$bp-xl-lg: 1200px;


// button reset
button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

* {
  box-sizing: border-box;
}

.schedContainer {
  display: flex;
  flex-direction: column;
  font-family: 'Inter';
  color: #fff;
  animation: enter .3s linear;


  .schedHeader {
    margin: 0;
    padding-top: 100px;
    padding-bottom: 30px;
  }

  .dayTabs {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    li {
      list-style: none;

      padding-top: 10px;
      padding-right: 15px;
      padding-left: 15px;
      padding-bottom: 5px;
      border-bottom: 1px solid #fff;
      font-size: 14px;

      // &:hover {
      //   background-color: #fff;
      //   color: black;
      // }

      &.active {
        background-color: #fff;
        color: black;
        border: 1px solid #fff;
        // font-weight: bold;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }

      button {
        text-decoration: none;
        color: #fff;

        // &:hover {
        //   background-color: #fff;
        //   color: black;
        // }

        &.active {
          color: black;
        }

      }
    }

    span {
      padding-top: 10px;
      padding-right: 15px;
      padding-left: 15px;
      padding-bottom: 5px;
      border-bottom: 1px solid #fff;
      font-size: 14px;

      &.active {
        background-color: #fff;
        color: black;
        border: 1px solid #fff;
        font-weight: bold;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
    }

    span:hover {
      cursor: pointer;
    }
  }

  .dailySchedule {
    padding-top: 30px;

    .weathContain {
      display: flex;
      justify-content: center;
      margin: 0px 20px;


      .weathImg {
        max-width: 100%;
      }
    }

    .eventBox {
      display: flex;
      background-color: #465674;
      margin: 10px 20px;
      max-width: 800px;
      margin: 20px auto;
      border-radius: 5px;
      text-align: left;
      align-content: center;
      animation: enter .3s linear;

      .eContent {
        display: flex;
        flex-direction: row;
        padding: 20px;
        width: 100%;

        @media (max-width: $bp-tablet) {
          flex-direction: column;
        }

        .eContentLeft {
          display: flex;
          flex-basis: 20%;
          align-items: center;
          justify-content: center;

          @media (max-width: $bp-tablet) {
            justify-content: center;
          }

          .time {
            font-size: 24px;
            font-weight: bold;
          }
        }

        .eContentRight {
          flex-basis: 80%;
          flex-direction: column;
        }

        .location {
          img {
            padding-right: 5px;
          }

          display: flex;

          a {
            color: #fff;
          }
        }
      }
    }
  }
}


// Animations
// @keyframes move_out {
//   0% {
//     top: 47px;
//   }
//   100% {
//     top: 200px;
//   }
// }
// @keyframes move_in {
//   0% {
//     top: -200px;
//   }
//   100% {
//     top: 47px;
//   }
// }
// @keyframes bump {
//   0% {
//     top: 16px;
//   }
//   25% {
//     top: 13px;
//   }
//   50% {
//     top: 16px;
//   }
//   75% {
//     top: 13px;
//   }
//   100% {
//     top: 16px;
//   }
// }
// @keyframes intro {
//   0% {
//     transform: translateY(-50%) scale(0) rotateX(10deg) rotateY(10deg)
//       rotateZ(40deg);
//   }
//   100% {
//     transform: translateY(-50%) scale(1) rotateX(0deg) rotateY(0deg)
//       rotateZ(0deg);
//   }
// }

@keyframes enter {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}