@import url('../assets/sass/vars.scss');

$bp-sm-mobile: 405px;
$bp-mobile:    567px;
$bp-tablet:    768px;
$bp-lg:        992px;
$bp-xl-lg:     1200px;


.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  color: #fff;

  h1 {
    font-family: 'DancingScript';
    padding-top: 65px;
    padding-bottom: 30px;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  p {
    max-width: 60%;

    @media (max-width: $bp-tablet) {
      max-width: 90%;
    }
  }

  .homeImg {
    max-width: 430px;

    @media (max-width: $bp-tablet) {
      width: 300px;
      max-width: 100%;
    }
  }
}