$bp-sm-mobile: 405px;
$bp-mobile:    567px;
$bp-tablet:    768px;
$bp-lg:        992px;
$bp-xl-lg:     1200px;

.links {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
  color: #fff;

  h1 {
    // color: #fff;
    padding-top: 65px;
  }

  a {
    color: #fff;
  }

  img {
    max-width: 600px;
    padding-left: 20px;
    padding-right: 20px;

    @media (max-width: $bp-mobile) {
      max-width: 100%;
    }
  }
}